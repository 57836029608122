// extracted by mini-css-extract-plugin
export var alignEnd = "SideMenu-module--alignEnd--caf72";
export var alignStart = "SideMenu-module--alignStart--b0499";
export var closeButton = "SideMenu-module--closeButton--59dfc";
export var expandButton = "SideMenu-module--expandButton--431e8";
export var hidden = "SideMenu-module--hidden--ca1d7";
export var humanDesignMenu = "SideMenu-module--humanDesignMenu--1df4e";
export var justifyCenter = "SideMenu-module--justifyCenter--023da";
export var justifyEnd = "SideMenu-module--justifyEnd--58864";
export var justifyEven = "SideMenu-module--justifyEven--1bcba";
export var justifyStart = "SideMenu-module--justifyStart--f8476";
export var mAuto = "SideMenu-module--mAuto--d2a5c";
export var mb0 = "SideMenu-module--mb0--7d768";
export var mb1 = "SideMenu-module--mb1--24916";
export var mb2 = "SideMenu-module--mb2--32dda";
export var mb3 = "SideMenu-module--mb3--cb2d0";
export var mb4 = "SideMenu-module--mb4--ab6e9";
export var menuItem = "SideMenu-module--menuItem--ad354";
export var menuList = "SideMenu-module--menuList--6841d";
export var mhAuto = "SideMenu-module--mhAuto--bebf8";
export var ml0 = "SideMenu-module--ml0--98ec4";
export var ml1 = "SideMenu-module--ml1--d3c18";
export var ml2 = "SideMenu-module--ml2--1916c";
export var ml3 = "SideMenu-module--ml3--d7c54";
export var ml4 = "SideMenu-module--ml4--1c847";
export var mlAuto = "SideMenu-module--mlAuto--5660f";
export var mr0 = "SideMenu-module--mr0--a83ae";
export var mr1 = "SideMenu-module--mr1--b03ed";
export var mr2 = "SideMenu-module--mr2--b1792";
export var mr3 = "SideMenu-module--mr3--24cfa";
export var mr4 = "SideMenu-module--mr4--ba84b";
export var mt0 = "SideMenu-module--mt0--15383";
export var mt1 = "SideMenu-module--mt1--e0355";
export var mt2 = "SideMenu-module--mt2--ca227";
export var mt3 = "SideMenu-module--mt3--78155";
export var mt4 = "SideMenu-module--mt4--751de";
export var nav = "SideMenu-module--nav--40288";
export var open = "SideMenu-module--open--c8f1e";
export var overlay = "SideMenu-module--overlay--7edae";
export var overlayOpen = "SideMenu-module--overlayOpen--9bbc1";