// extracted by mini-css-extract-plugin
export var alignEnd = "Footer-module--alignEnd--23aa4";
export var alignStart = "Footer-module--alignStart--4e97d";
export var certificate = "Footer-module--certificate--83d9d";
export var footer = "Footer-module--footer--acb34";
export var justifyCenter = "Footer-module--justifyCenter--f2867";
export var justifyEnd = "Footer-module--justifyEnd--db5bd";
export var justifyEven = "Footer-module--justifyEven--fd968";
export var justifyStart = "Footer-module--justifyStart--32aa8";
export var leftBlock = "Footer-module--leftBlock--94efe";
export var mAuto = "Footer-module--mAuto--343c0";
export var mb0 = "Footer-module--mb0--9dc0b";
export var mb1 = "Footer-module--mb1--595c0";
export var mb2 = "Footer-module--mb2--e3819";
export var mb3 = "Footer-module--mb3--c71d4";
export var mb4 = "Footer-module--mb4--5ac70";
export var mhAuto = "Footer-module--mhAuto--52e68";
export var ml0 = "Footer-module--ml0--f42ed";
export var ml1 = "Footer-module--ml1--cacdd";
export var ml2 = "Footer-module--ml2--a4275";
export var ml3 = "Footer-module--ml3--b7b13";
export var ml4 = "Footer-module--ml4--2b65b";
export var mlAuto = "Footer-module--mlAuto--93b1d";
export var mr0 = "Footer-module--mr0--c3340";
export var mr1 = "Footer-module--mr1--3f971";
export var mr2 = "Footer-module--mr2--6724d";
export var mr3 = "Footer-module--mr3--c4d8c";
export var mr4 = "Footer-module--mr4--1dac6";
export var mt0 = "Footer-module--mt0--c610c";
export var mt1 = "Footer-module--mt1--479a6";
export var mt2 = "Footer-module--mt2--88edc";
export var mt3 = "Footer-module--mt3--7066e";
export var mt4 = "Footer-module--mt4--8fe04";