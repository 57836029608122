// extracted by mini-css-extract-plugin
export var alignEnd = "Menu-module--alignEnd--5479b";
export var alignStart = "Menu-module--alignStart--89067";
export var hidden = "Menu-module--hidden--03a23";
export var humanDesignMenu = "Menu-module--humanDesignMenu--fe7eb";
export var justifyCenter = "Menu-module--justifyCenter--f2a23";
export var justifyEnd = "Menu-module--justifyEnd--05028";
export var justifyEven = "Menu-module--justifyEven--0cc7e";
export var justifyStart = "Menu-module--justifyStart--38b68";
export var mAuto = "Menu-module--mAuto--74953";
export var mb0 = "Menu-module--mb0--ac60c";
export var mb1 = "Menu-module--mb1--2af78";
export var mb2 = "Menu-module--mb2--f7054";
export var mb3 = "Menu-module--mb3--50af8";
export var mb4 = "Menu-module--mb4--fa2c2";
export var menuItem = "Menu-module--menuItem--687c9";
export var menuList = "Menu-module--menuList--b92a3";
export var mhAuto = "Menu-module--mhAuto--48eb6";
export var ml0 = "Menu-module--ml0--8ce3d";
export var ml1 = "Menu-module--ml1--ce4d0";
export var ml2 = "Menu-module--ml2--dcd54";
export var ml3 = "Menu-module--ml3--5076c";
export var ml4 = "Menu-module--ml4--36506";
export var mlAuto = "Menu-module--mlAuto--cd33e";
export var mr0 = "Menu-module--mr0--bda41";
export var mr1 = "Menu-module--mr1--ab7be";
export var mr2 = "Menu-module--mr2--0d42b";
export var mr3 = "Menu-module--mr3--a9dc2";
export var mr4 = "Menu-module--mr4--7e6cb";
export var mt0 = "Menu-module--mt0--6f0d3";
export var mt1 = "Menu-module--mt1--1ffd1";
export var mt2 = "Menu-module--mt2--f64ff";
export var mt3 = "Menu-module--mt3--6b1bc";
export var mt4 = "Menu-module--mt4--7f8d0";
export var nav = "Menu-module--nav--2c8b5";